// Generated by Framer (915daba)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/TWWhF0RGI";

const cycleOrder = ["wGjTBn8tX", "PDE2Pgd_H", "oosmUhyrg"];

const serializationHash = "framer-a5JyG"

const variantClassNames = {oosmUhyrg: "framer-v-nh4ksq", PDE2Pgd_H: "framer-v-1bflwtk", wGjTBn8tX: "framer-v-1ptyxrv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 4.5, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Black paused": "oosmUhyrg", Black: "wGjTBn8tX", White: "PDE2Pgd_H"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, KwHvJtP8B: title ?? props.KwHvJtP8B ?? "Tracking 1", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "wGjTBn8tX"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KwHvJtP8B, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wGjTBn8tX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearppyhzs = activeVariantCallback(async (...args) => {
await delay(() => setVariant("PDE2Pgd_H"), 100)
})

useOnVariantChange(baseVariant, {default: onAppearppyhzs, oosmUhyrg: undefined})

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1ptyxrv", className, classNames)} data-border data-framer-name={"Black"} data-highlight layoutDependency={layoutDependency} layoutId={"wGjTBn8tX"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-76b118f8-b079-4732-ac12-af5a3a96814e, rgba(255, 255, 255, 0.48))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-cf87ee3f-f43b-4ffb-894b-5b7443ecbe20, rgb(0, 0, 0))", ...style}} {...addPropertyOverrides({oosmUhyrg: {"data-framer-name": "Black paused", "data-highlight": undefined}, PDE2Pgd_H: {"data-framer-name": "White"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1co8ro1"} layoutDependency={layoutDependency} layoutId={"IqAdFgIWU"} style={{backgroundColor: "var(--token-5b4355ee-c6a9-4535-9c0d-4b183110acf9, rgb(255, 255, 255))"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1tsneiz"} data-styles-preset={"TWWhF0RGI"}>Tracking 1</motion.p></React.Fragment>} className={"framer-12r4wz2"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ccHa3hvt6"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={KwHvJtP8B} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-a5JyG.framer-1n715ba, .framer-a5JyG .framer-1n715ba { display: block; }", ".framer-a5JyG.framer-1ptyxrv { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 8px; position: relative; width: min-content; }", ".framer-a5JyG .framer-1co8ro1 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; top: 0px; width: 0%; z-index: 2; }", ".framer-a5JyG .framer-12r4wz2 { flex: none; height: auto; mix-blend-mode: difference; position: relative; white-space: pre; width: auto; z-index: 2; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-a5JyG.framer-1ptyxrv { gap: 0px; } .framer-a5JyG.framer-1ptyxrv > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-a5JyG.framer-1ptyxrv > :first-child { margin-left: 0px; } .framer-a5JyG.framer-1ptyxrv > :last-child { margin-right: 0px; } }", ".framer-a5JyG.framer-v-1bflwtk .framer-1co8ro1 { width: 100%; }", ...sharedStyle.css, ".framer-a5JyG[data-border=\"true\"]::after, .framer-a5JyG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 88
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PDE2Pgd_H":{"layout":["auto","auto"]},"oosmUhyrg":{"layout":["auto","auto"]}}}
 * @framerVariables {"KwHvJtP8B":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZzmbxEClc: React.ComponentType<Props> = withCSS(Component, css, "framer-a5JyG") as typeof Component;
export default FramerZzmbxEClc;

FramerZzmbxEClc.displayName = "Loading bar";

FramerZzmbxEClc.defaultProps = {height: 34, width: 88};

addPropertyControls(FramerZzmbxEClc, {variant: {options: ["wGjTBn8tX", "PDE2Pgd_H", "oosmUhyrg"], optionTitles: ["Black", "White", "Black paused"], title: "Variant", type: ControlType.Enum}, KwHvJtP8B: {defaultValue: "Tracking 1", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerZzmbxEClc, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})